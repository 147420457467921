<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="back">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Settings
      </button>
    </div>
    <p class="text-2xl pb-2 text-dark-gray">Your Subscriptions</p>
    <div>
      <div
        @click="showSubscriptions = !showSubscriptions"
        :class="
          showSubscriptions
            ? `rounded-t-xl border-2 border-b-0`
            : `rounded-xl border-2`
        "
        class="p-2 flex flex-row justify-between items-center gap-2 border-moonstone"
      >
        <p class="text-lg font-medium">Subscriptions</p>
        <div>
          <font-awesome-icon
            v-show="this.showSubscriptions === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showSubscriptions === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if="showSubscriptions" class="flex flex-col">
        <div
          v-if="subscriptionPayments.length <= 0"
          class="rounded-b-xl border-2 border-moonstone p-2"
        >
          <p>No subscriptions yet</p>
        </div>
        <div
          :class="
            showSubscriptions
              ? `last:rounded-b-xl last:border-b-2 border-b-0`
              : `rounded-xl`
          "
          class="border-2 border-moonstone"
          v-for="payment in subscriptionPayments"
          :key="payment.id"
        >
          <router-link
            :to="{
              name: 'ManageSubscription',
              params: { subscriptionId: payment.id },
            }"
            class="w-full line-clamp-2"
          >
            <SubscriptionPayment
              :price-type="payment.price.priceType"
              :price="payment.paidAtPrice"
              :name="payment.price.product.contentOwnerName"
              :profile-picture="
                payment.price.product.contentOwnerProfilePicturePresignedUrl
              "
              @click="
                goToManageSubscription(
                  payment.id,
                  payment.price.product.contentOwnerName,
                  payment.price.product.contentOwnerId,
                  payment.price.price,
                  payment.price.priceType,
                  payment.subscriptionStatus,
                  payment.endDate
                )
              "
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionPayment from '@/components/Insider/Products/SubscriptionPayment.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ManageSubscriptions',
  data() {
    return {
      showSubscriptions: false,
    };
  },
  components: {
    SubscriptionPayment,
  },
  computed: {
    ...mapGetters({
      payments: 'customerStoreModule/getPayments',
    }),
    subscriptionPayments() {
      return this.payments.filter(
        (item) => item.price.product.productType === 'INSIDER_SUBSCRIPTION'
      );
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    goToManageSubscription(id, name, userId, price, type, status, endDate) {
      this.$store.commit('manageSubscriptionStoreModule/setId', id);
      this.$store.commit('manageSubscriptionStoreModule/setName', name);
      this.$store.commit('manageSubscriptionStoreModule/setUserId', userId);
      this.$store.commit('manageSubscriptionStoreModule/setPrice', price);
      this.$store.commit('manageSubscriptionStoreModule/setType', type);
      this.$store.commit('manageSubscriptionStoreModule/setStatus', status);
      this.$store.commit('manageSubscriptionStoreModule/setEndDate', endDate);
      this.$router.push({ name: 'ManageSubscription' });
    },
  },
  mounted() {
    this.$store.dispatch('customerStoreModule/getPayments');
  },
  unmounted() {
    this.$store.commit('customerStoreModule/clear');
  },
};
</script>